import React, { useEffect, useState } from 'react'
import UserService from '../services/UserService';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { getNotification } from "../Redux/Action/action.js";
// import WebSocketVideoStream from './WebSocketVideoStream.jsx';


const { REACT_APP_ALL_CAMERA_URL, REACT_APP_REDIRECT_URI, REACT_APP_CAMERA_URL, REACT_APP_CAMERA_LINK } = process.env
function AllCamera() {
    const dispatch = useDispatch()

    const [clickedCamera, setClickedCamera] = useState(null);
    const [dynamicCameraList, setDynamicCameraList] = useState([])
    const [loader, setLoader] = useState(false)


    useEffect(() => {
        getCameraList()
    }, [])




    const getCameraList = async () => {
        if (UserService.isLoggedIn()) {
            try {
                setLoader(true)
                console.log("started");
                const config = {
                    method: "get",
                    url: `${REACT_APP_ALL_CAMERA_URL}/cameras`,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        // access_token: UserService.getToken(),
                    },
                    params: {
                        user_id: UserService.getUserId(),
                        // camera_Id: "None"
                    },
                };
                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    setDynamicCameraList(getResponse.data)
                }
            } catch (err) {
                setLoader(false);
                console.log("Error", err);
                if (err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/base` });
                    }, 3000);
                }
                else
                    dispatch(
                        getNotification({
                            message: "We are experiencing high demand. Please try after a few minutes.",
                            type: "default",
                        })
                    );
            }
            setLoader(false);
        } else {
            dispatch(
                getNotification({
                    message: "Session expired! Plesae log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/base` });
            }, 3000);
        }

    };
    const cameraIds = ['1', '2'];
    return (
        <div className="container-fluid mx-auto p-4">
            {/* <WebSocketVideoStream /> */}
            {/* <WebSocketVideoStream cameraIds={cameraIds} /> */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                {dynamicCameraList.map((camera, index) => (
                    <div
                        key={index}
                        className="relative group"
                        onClick={() => setClickedCamera(camera.id)}
                    >
                        <img
                            loading="lazy"
                            className="w-full h-70 object-cover transition-transform duration-500 transform group-hover:scale-105 cursor-pointer"
                            // src={`${camera.id}`}
                            src={`${REACT_APP_CAMERA_URL + camera.id}`}
                            alt={camera.name}
                        />
                        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 transition-opacity duration-500 opacity-0 group-hover:opacity-100">
                            {camera.name}
                        </div>
                    </div>
                ))}
            </div>

            {clickedCamera && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50 mt-5">
                    <div className="h-[600px] w-[1200px]">
                        <img
                            loading="lazy"
                            src={`${REACT_APP_CAMERA_URL + clickedCamera}`}
                            alt="Camera View"
                            className="w-full h-full object-fill"
                        />
                    </div>

                    <button
                        className="absolute top-5 right-5 text-xl"
                        onClick={() => setClickedCamera(null)}
                    >
                        ✖
                    </button>
                </div>
            )}
        </div>
    );
}

export default AllCamera